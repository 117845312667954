<template>
  <main role="main">
    <meta-title :title="metaTitle"></meta-title>

    <section class="section section-tiles">
      <div class="container m-container-only-s">
        <ul>
          <li v-for="(item, index) in items" :key="index">
            <router-link :to="item.pathToIndex.replaceAll('/index.json', '')">
              <figure
                v-bind:style="{
                  'background-image':
                    'url(/storage/' +
                    ([14, 20].indexOf(item.id) === -1
                      ? item.image
                      : item.logo) +
                    ')'
                }"
              ></figure>
              <p>{{ item.title }}</p>
            </router-link>
          </li>
        </ul>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "Press",
  title() {
    return this.$t("pages.press");
  },
  data: () => {
    return {
      items: []
    };
  },
  async mounted() {
    const { data } = await this.axios.get(
      `/storage/${
        this.$route.query.test === "1"
          ? "test/" + this.$route.query.journal + "/"
          : ""
      }press/index.json`
    );
    this.items = data.filter(item => !!item.id);
  }
};
</script>

<style scoped></style>
