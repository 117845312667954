var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{attrs:{"role":"main"}},[_c('meta-title',{attrs:{"title":_vm.metaTitle}}),_c('section',{staticClass:"section section-tiles"},[_c('div',{staticClass:"container m-container-only-s"},[_c('ul',_vm._l((_vm.items),function(item,index){return _c('li',{key:index},[_c('router-link',{attrs:{"to":item.pathToIndex.replaceAll('/index.json', '')}},[_c('figure',{style:({
                'background-image':
                  'url(/storage/' +
                  ([14, 20].indexOf(item.id) === -1
                    ? item.image
                    : item.logo) +
                  ')'
              })}),_c('p',[_vm._v(_vm._s(item.title))])])],1)}),0)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }